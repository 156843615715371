.fm-webview .fm-container {

}

.fm-webview-title {
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  line-height: 40px;
  margin: 10px 10px 30px 10px;
}

.fm-webview-text {
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  margin: 15px 10px;
}

.fm-webview-list {
  margin-left: 15px;
}

/* TEMP MAX */

.fm-max {

}

.fm-ruru-image {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/support/ruru.jpg');
  background-position: 50%;
  background-size: cover;
  height: 250px;
}

.fm-top-image {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/email/give-20.jpg');
  background-position: 50% 0;
  background-size: cover;
  height: 250px;
}

.fm-ruru-li {
  margin: 5px 20px 15px 50px;
}

.fm-max-image {
  background-image: url('https://storage.googleapis.com/flamingo-static/images/support/max-plus-wgtn.jpg');
  background-position: 50%;
  background-size: cover;
  height: 250px;
}

.fm-max-photo {
  width: 100%;
  max-width: 100%;
}

.fm-max-title {
  font-size: 40px;
  font-weight: 900;
  border-left: 10px solid #ff206e;
  padding: 15px 10px 15px 20px;
  line-height: 50px;
  font-weight: 600;
}

.fm-max-item {
  padding: 30px;
  display: flex;
  align-items: flex-start;
}

.fm-max-text {
  margin: 20px 30px;
  line-height: 25px;
  letter-spacing: 0.2px;
  font-size: 16px;
}

.fm-max-list {
  line-height: 25px;
  letter-spacing: 0.2px;
  margin-left: 50px;
}

.fm-max-item-title {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 10px;
  font-weight: 600;
}

.fm-max-item-text {
  letter-spacing: 0.2px;
  line-height: 25px;
}

.fm-max-item-img {
  width: 60px;
  margin: 5px 25px 0 0;
  flex: 0 0 60px;
}

.fm-max-end {
  letter-spacing: 0.2px;
  line-height: 25px;
  padding: 30px;
}

.fm-max-code {
  background: $primary;
  color: #fff;
  text-align: center;
  margin: 5px auto;
  font-size: 18px;
  font-weight: 600;
  width: 200px;
  padding: 10px 0;
  border-radius: 3px;
  letter-spacing: 1px;
}

.fm-max-terms {
  letter-spacing: 0.2px;
  color: #999;
  font-size: 14px;
  margin: 20px 30px;
}

.fm-brake {
  padding: 15px 0;
}

.fm-brake-box {
  display: flex;
  padding: 15px 10px;
  align-items: center;
}

.fm-brake-photo {
  flex: 0 0 100px;
  height: 100px;
  background-size: 100px;
  margin: 10px;
}

.fm-brake-text {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.2px;
  margin: 10px;
  font-weight: 600;
}

.fm-brake-intro {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.2px;
  margin: 20px 20px 0 20px;
}

.fm-brake-box:nth-child(odd) {
  background: #ff206e;

  .fm-brake-photo {
    background-color: #fff;
    border-radius: 50px;
  }

  .fm-brake-text {
    color: #fff;
  }
}

.fm-how-to-safety-item-image {
  width: 80px;
  height: 80px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.fm-how-to-safety-items {
  margin: 0 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 10%;
}

.fm-how-to-safety-item {
  flex: 40%;
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.fm-how-to-safety-item-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin: 20px 0 10px;
}

.fm-how-to-safety-item-message {
  font-size: 15px;
  line-height: 22px;
  margin-top: 15px;
  text-align: center;
  font-weight: 500;
}

/* GO PASS */

.fm-go {
  display: flex;
  margin: 30px;
  flex-direction: row;
  gap: 10px;
}

.fm-go-plan {
  flex: 1;
  border: 2px solid #eeeeee;
  display: flex;
  flex-direction: column;
}

.fm-go-plan-pink {
  border: 2px solid #ff206e;
}

.fm-go-plan-bottom {
  background: #eeeeee;
  padding: 6px 0 4px 0;
}

.fm-go-plan-pink .fm-go-plan-bottom {
  background: #ff206e;
}

p.fm-go-plan-savings {
  color: #6c6c6c;
  text-align: center;
  font-size: 9px;
  font-weight: 600;
  line-height: 14px;
}

.fm-go-plan-pink .fm-go-plan-savings {
  color: #fff;
}

p.fm-go-plan-name {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 10px 0 6px 0;
}

p.fm-go-plan-price {
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  color: #ff206e;
  line-height: 30px;
}

p.fm-go-plan-per {
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #888;
  line-height: 20px;
  margin-bottom: 12px;
}

.fm-vote-code {
  color: #111;
  background-color: #f89828;
  padding: 2px 5px;
}

.fm-vote-button {
  color: #111;
  background-color: #f89828;
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px 18px;
  line-height: 1.6;
  display: block;
  margin: 20px auto 40px auto;
  width: 250px;
}

a.fm-max-item-card {
  margin: 40px 30px 10px 30px;
  display: block;
  padding: 15px;
  background: #ff206e;
  border-radius: 5px;
}

p.fm-max-item-card-title {
  font-weight: 800;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}

p.fm-max-item-card-text {
  font-weight: 500;
  color: #fff;
  opacity: 0.9;
  font-size: 15px;
  line-height: 20px;
}

p.fm-max-item-card-link {
  font-size: 16px;
  font-weight: 800;
  color: #fff;
  margin-top: 10px;
  line-height: 20px;
}

.fm-max-ref {
  background: #ff206e;
  margin: 40px 30px 10px 30px;
  border-radius: 4px;
  padding: 12px 15px;
}

p.fm-max-ref-text {
  color: #fff;
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
}

p.fm-max-ref-code {
  font-size: 24px;
  text-align: center;
  font-weight: 800;
  color: #fff;
  margin: 18px 0 18px 0;
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;
  -webkit-user-select: all;
  user-select: all;
}

p.fm-max-ref-note {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  line-height: 20px;
}

.fm-max-title2 {
  font-size: 22px;
  padding: 20px;
  line-height: 40px;
  font-weight: 800;
  background: #ff206e;
  color: #fff;
  text-align: center;
}

.fm-max-list2 {
  margin-left: 20px;
}

.fm-max-list2 li::marker {
  color: #ff206e;
  font-weight: 600;
}


p.fm-term-title {
  margin: 10px 30px;
  font-size: 12px;
  color: #999;
  font-weight: 600;
}

p.fm-term-item {
  margin: 5px 30px;
  font-size: 12px;
  color: #999;
}

.fm-max2 {
  font-size: 0;
}

.fm-term-item:last-child {
  margin-bottom: 30px;
}
